.MuiOutlinedInput-notchedOutline {
  border-color: #C4C4C4 !important;
}

.css-sg2jfx-MuiInputBase-root-MuiInput-root.Mui-error:after {
  border-bottom-color: #b1b1b1 !important;
}

.css-1a0wq1u-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
  border: 1px solid #e8eaeeb3
}

.css-gzlx6l-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
  border: 1px solid #e8eaeeb3
}

@media (min-width: 168px) and (max-width: 852px) {
  .css-8v6rio-MuiButtonBase-root-MuiIconButton-root {
    display: block !important;
  }

  .MuiDrawer-paper {
    display: block !important;
  }
  .css-1xi8kwe-MuiDrawer-docked .MuiDrawer-paper {
    display: none !important;
  }

  .drawer-mobile {
    display: inline !important;
  }
}

@media (min-width: 960px) and (max-width: 1280px) {
  .MuiBox-root .css-1hwv2q0 {
    width: calc(100% - 270px);
    float: right;
  }
}

@media (max-width: 960px) {
  .sidebarDesktop {
    display: none !important;
  }
  .menu {
    display: block !important;
  }
}

.fullWidth {
  width: 100% !important;
}

@media (min-width: 852px) {
  .fullWidth {
    padding-right: 6px !important;
  }
}

.bold {
  font-weight: bold;
}

.css-1vam7s3-MuiGrid-root {
  display: flex;
  justify-content: space-between;
}

.skeleton {
  border-radius: 10px;
}